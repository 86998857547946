<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.746" x2="0.275" y2="1">
     <stop offset="0" stop-color="#19d945"/>
     <stop offset="0.03" stop-color="#43f86c"/>
     <stop offset="1" stop-color="#07a550"/>
    </linearGradient>
   </defs>
   <g id="Xbox" transform="translate(-290 -528)">
    <rect :fill="background" data-name="Rectangle 21" height="60" id="Rectangle_21" transform="translate(290 528)" width="60"/>
    <path d="M23.914,20.577c2.858,3.5,4.175,6.375,3.51,7.659-.51.974-3.658,2.878-5.975,3.607a17.564,17.564,0,0,1-6.478.658,16.048,16.048,0,0,1-7.1-2.516c-1.8-1.174-2.207-1.658-2.207-2.62,0-1.929,2.123-5.31,5.755-9.169,2.065-2.187,4.936-4.755,5.252-4.684.607.135,5.439,4.846,7.246,7.065ZM12.216,9.324C10.3,7.589,8.467,5.847,6.641,5.234c-.981-.329-1.052-.31-1.852.523A15.993,15.993,0,0,0,.9,13.654a12.469,12.469,0,0,0-.271,3.9,16.517,16.517,0,0,0,2.613,7.8c.613.942.781,1.116.6.639a7.616,7.616,0,0,1,.613-4.129A49.3,49.3,0,0,1,12.216,9.324Zm20.105,4.1c-1.09-5.162-4.355-8.407-4.813-8.407a6.022,6.022,0,0,0-2.323.9,27.941,27.941,0,0,0-4.149,3.407c2.736,3.439,6.594,8.995,7.93,13.053a7.732,7.732,0,0,1,.477,3.375c-.11.548-.11.548.09.3a19.558,19.558,0,0,0,1.639-2.807,18.939,18.939,0,0,0,1.2-3.788,20.96,20.96,0,0,0-.052-6.026ZM9.164,2.821c3.078-.161,7.078,2.226,7.375,2.284a12.841,12.841,0,0,0,1.394-.626C22.055,2.472,24,2.814,24.862,2.853A15.976,15.976,0,0,0,9.77,2.1c-1.51.716-1.549.768-.607.723Z" data-name="Icon awesome-xbox" id="Icon_awesome-xbox" style="fill: url(#linear-gradient);" transform="translate(303.434 541.441)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>